import axios from 'axios'
import { useEffect, useState, useContext } from 'react'
import { AccountContext } from 'src/components/account/context'
import OrderBreadcrumb from 'src/components/account/OrderDetail/OrderBreadcrumb'
import OrderHeader from 'src/components/account/OrderDetail/OrderHeader'
import OrderDetails from 'src/components/account/OrderDetail/OrderDetails'
import CancellationNotAllowed from 'src/components/account/OrderDetail/CancellationModal/CancellationNotAllowed'
import CancellationAllowed from 'src/components/account/OrderDetail/CancellationModal/CancellationAllowed'
import OrderTimeLine from 'src/components/account/Orders/components/Timeline/OrderTimeLine'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'
import { TrackingDetails } from 'src/components/account/Orders/components/Tracking/TrackingDetails'

import OrderContentSkeleton from './OrderContentSkeleton'

type OrderContentProps = {
  orderId: string
}

const OrderContent = ({ orderId }: OrderContentProps) => {
  const [cancelNotAllowedOpen, setCancelNotAllowedOpen] = useState(false)
  const [cancelAllowedOpen, setCancelAllowedOpen] = useState(false)
  const { memberAuthToken, userDecathlon } = useContext(AccountContext)

  const [page, setPage] = useState<number>(0)
  const [orders, setOrders] = useState<any>()
  const [order, setOrder] = useState<any>()

  function trackEvent(
    eventAction: string,
    eventPage: string,
    dimension?: string
  ) {
    let event = makeEventTrack({
      eventAction,
      eventPage,
    })

    if (dimension) {
      event = { ...event, dimension26: dimension }
    }

    sendEvent(event)
  }

  useEffect(() => {
    if (!memberAuthToken || !page) {
      return
    }

    const getOrderList = async () => {
      const { data } = await axios.post('/api/getOrderList', {
        email: memberAuthToken,
        page,
      })

      setOrders(data.response.data.DktApp_GetOrderList.list)
    }

    getOrderList()
  }, [memberAuthToken, page])

  useEffect(() => {
    if (!orders) {
      return
    }

    const filteredOrder = orders.find(
      (orderItem: any) => orderItem.orderId === orderId
    )

    setOrder(filteredOrder)
  }, [orders, orderId])

  useEffect(() => {
    if (!userDecathlon) {
      return
    }

    window.decathlonAnalytics = window.decathlonAnalytics || {}

    window.decathlonAnalytics = {
      user_info: {
        user_id: userDecathlon?.getUserProfile.claims.email,
        login_status: 'Logado',
        source_user_id: 'Vtex',
      },
    }
  }, [userDecathlon])

  useEffect(() => {
    const urlSearchString = window.location.search

    const params = new URLSearchParams(urlSearchString)
    const hasPage = params.get('page')

    setPage(hasPage ? parseInt(hasPage, 10) : 1)
  }, [])

  if (!order) {
    return (
      <div className="w-full max-w-[844px]">
        <OrderContentSkeleton orderId={orderId} />
      </div>
    )
  }

  const orderGroup = order?.orderId?.split('-')[0]

  const isOrderSplit =
    orders?.filter((orderItem: any) => orderItem?.orderId?.includes(orderGroup))
      .length > 1

  return (
    <section className="w-full md:max-w-[845px]">
      {cancelNotAllowedOpen && (
        <CancellationNotAllowed
          cancelNotAllowedOpen={cancelNotAllowedOpen}
          setCancelNotAllowedOpen={setCancelNotAllowedOpen}
        />
      )}
      {cancelAllowedOpen && (
        <CancellationAllowed
          cancelAllowedOpen={cancelAllowedOpen}
          setCancelAllowedOpen={setCancelAllowedOpen}
          orderId={orderId}
        />
      )}
      <OrderBreadcrumb orderId={orderId} />

      <OrderHeader
        {...order}
        setCancelNotAllowedOpen={setCancelNotAllowedOpen}
        setCancelAllowedOpen={setCancelAllowedOpen}
        trackEvent={(eventAction, eventPage, dimension) =>
          trackEvent(eventAction, eventPage, dimension)
        }
      />

      {order.timeline.currentStatus.name !== 'finished' && (
        <>
          <OrderTimeLine
            trackEvent={(eventAction, eventPage, dimension) => {
              trackEvent(eventAction, eventPage, dimension)
            }}
            timeline={order.timeline}
          />
          <TrackingDetails timeline={order.timeline} />
        </>
      )}

      <OrderDetails
        trackEvent={(eventAction, eventPage, dimension) =>
          trackEvent(eventAction, eventPage, dimension)
        }
        {...order}
        isOrderSplit={isOrderSplit}
      />

      {order.timeline.currentStatus.name === 'finished' && (
        <>
          <OrderTimeLine
            trackEvent={(eventAction, eventPage, dimension) =>
              trackEvent(eventAction, eventPage, dimension)
            }
            timeline={order.timeline}
          />
          <TrackingDetails timeline={order.timeline} />
        </>
      )}
    </section>
  )
}

export default OrderContent
